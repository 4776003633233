import React, { useState, useEffect } from "react";
import { Grid, Box, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import pic from "../../assets/gesh.png";
import VideoPlayer from "../common/VideoPlayer";
import "./Signup.css";
import { MuiOtpInput } from "mui-one-time-password-input";
import AccountVerifiedModal from "./AccountVerifiedModal";
import { sendOTPtoEmail } from "../../api/auth";
import { useAuth } from "../context/AuthContext";
import { textFieldStyles } from "../../styles/inputStyles";

const styles = {
  fontSize: "13px",
  // borderColor: "#E0E0E0",
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    borderColor: "#E0E0E0",
    "& .MuiOutlinedInput-root.Mui-focused fieldset": {
      borderColor: "#C5D2CB", // Conditional border color
    },
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "rgba(0, 0, 0, 0.60)",
    fontFamily: "Inter",
  },
  "& .MuiFormHelperText-root": {
    color: "red", // Custom helper text color
  },
  "& .MuiInputBase-input": {
    fontFamily: "Inter",
    borderColor: "#EEEEEE",
    maxHeight: "40px",
    fontSize: "14px",
    padding: "11.5px 12px", // Adjust the padding to reduce the size
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Inter",
    fontSize: "13px",
    transform: "translate(12px, 12px) scale(1)", // Adjust the label position
    color: "#787878",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(15px, -7px) scale(0.8)", // Adjust the label position when it shrinks
  },
};

const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [otperror, setOtpError] = useState(false);
  const [status, setStatus] = useState("login");
  const [otp, setOtp] = useState("");
  const [secs, setSecs] = useState(30);
  const [isRunning, setIsRunning] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [open, setOpen] = useState(false);
  const { verifyOtpWithBackend } = useAuth();
  const [helperText, setHelperText] = useState({
    email: "",
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleOTP = async () => {
    if (otp.length < 6) {
      alert(`Please Enter a Valid OTP`);
    }

    const payload = {
      user_email: email,
      user_otp: otp,
    };
    // setOpen(true);
    let response = await verifyOtpWithBackend(payload);
    if (response?.status && response?.status === 200) {
      setOpen(true);
    } else {
      setOtpError(true);
      alert("Could not verify OTP");
    }
  };
  const resendOTP = () => {
    startTimer();
    handleSubmit();
  };

  useEffect(() => {
    let timer;
    if (isRunning && secs > 0) {
      timer = setInterval(() => {
        setSecs((prevSecs) => {
          if (prevSecs <= 1) {
            clearInterval(timer);
            setIsRunning(false);
            return 0;
          }
          return prevSecs - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRunning, secs]);

  const startTimer = () => {
    setSecs(30);
    setIsRunning(true);
  };
  // useEffect(() => {
  //   const isEmailValid = email.trim() !== "" && validateEmail(email);
  //   const shouldShowError = email.trim() !== "" && !isEmailValid;

  //   setHelperText({
  //     email: shouldShowError ? "Please enter a valid email address" : "",
  //   });

  //   setError(shouldShowError);

  //   setIsFormValid(isEmailValid);
  // }, [email]);

  useEffect(() => {
    if (email !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [email]);

  useEffect(() => {
    if (status === "otp") startTimer();
  }, [status]);
  const handleSubmit = async () => {
    const isEmailValid = email.trim() !== "" && validateEmail(email);
    const payload = {
      user_email: email,
    };
    // setStatus("otp");
    if (isEmailValid) {
      setHelperText({ email: "" });
      setIsFormValid(true);
      let response = await sendOTPtoEmail(payload);
      if (response?.data?.status === 200) {
        setStatus("otp");
      } else {
        setHelperText({ email: response?.error });
        setStatus("login");
      }
    } else {
      // setIsFormValid(false);
      setHelperText({ email: "Please enter a valid email address" });
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleOTP();
    }
    setOtpError(false);
  }, [otp]);

  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid item xs={12} md={6} style={{ height: "100%", width: "100%" }}>
        <VideoPlayer />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box style={{ maxWidth: "400px" }}>
          <img
            src={pic}
            alt="Logo"
            style={{ width: "100px", marginBottom: "20px" }}
          />
          <h1
            style={{
              fontWeight: 500,
              fontSize: "30px",
            }}
          >
            Register Account!
          </h1>
          <p
            style={{
              marginTop: "16px",
              marginBottom: "3rem",
              fontSize: "1rem",
              color: "#8692A6",
              lineHeight: "28px",
              fontWeight: "400",
            }}
          >
            Join us to access sustainability reports and track your progress
            towards a greener future.
          </p>
          {status === "login" ? (
            <>
              <TextField
                fullWidth
                label="Email Address"
                variant="outlined"
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={error.email}
                helperText={helperText.email}
                // style={{
                //   marginBottom: 20,
                //   borderRadius: "10px",
                // }}
                // sx={{
                //   "& .MuiOutlinedInput-root": {
                //     "&.Mui-focused fieldset": {
                //       borderColor: "#369D9C",
                //     },
                //   },
                //   "& .MuiInputLabel-root.Mui-focused": {
                //     color: "#369D9C",
                //     fontFamily: "Inter",
                //   },
                //   "& .MuiInputBase-input": {
                //     fontFamily: "Inter",
                //   },
                //   "& .MuiInputLabel-root": {
                //     fontFamily: "Inter",
                //   },
                // }}
                sx={textFieldStyles}
              />
              <button
                className="signup_verify_btn"
                type="submit"
                onClick={handleSubmit}
                disabled={!isFormValid}
              >
                Verify
              </button>

              <p className="login_text">
                Already have an account?{" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/login")}
                >
                  Login
                </span>
              </p>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    pb: 1,
                    fontWeight: "500",
                    fontSize: "0.8rem",
                    color: "#585555",
                  }}
                >
                  {email}
                </Typography>

                <Typography
                  onClick={() => {
                    setOtp("");
                    setStatus("login");
                  }}
                  color={"primary"}
                  sx={{
                    fontFamily: "Inter",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "500",
                    background:
                      "linear-gradient(101.74deg, #369D9C 0%, #28814D 100%)",
                    WebkitBackgroundClip: "text", // Ensures the background is applied to the text
                    WebkitTextFillColor: "transparent", // Makes the text transparent so the gradient shows
                  }}
                >
                  Edit Email
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: ".2rem",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  marginBottom: "1.8rem",
                }}
              >
                <svg width={25} height={25} viewBox="0 0 32 32" fill="none">
                  <g clipPath="url(#clip0_4184_16343)">
                    <path
                      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                      stroke="#B7B7B7"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 9V16H23"
                      stroke="#B7B7B7"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4184_16343">
                      <rect width={32} height={32} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    minWidth: "35px",
                    fontFamily: "Inter",
                    background:
                      "linear-gradient(101.74deg, #369D9C 0%, #28814D 100%)",
                    WebkitBackgroundClip: "text", // Ensures the background is applied to the text
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {secs}s
                </Typography>
                <div>
                  <Typography
                    onClick={(e) => {
                      if (isRunning) {
                        e.preventDefault();
                      } else {
                        resendOTP();
                      }
                    }}
                    color={isRunning ? "#b7b7b7" : "primary"}
                    sx={{
                      cursor: !isRunning ? "pointer" : "default",
                      fontSize: "16px",
                      fontWeight: "500",
                      fontFamily: "Inter",
                      background: !isRunning
                        ? "linear-gradient(101.74deg, #369D9C 0%, #28814D 100%)"
                        : "none", // No background gradient when not running
                      WebkitBackgroundClip: !isRunning ? "text" : "unset", // Only clip when gradient is applied
                      WebkitTextFillColor: !isRunning
                        ? "transparent"
                        : "#B7B7B7", // Apply gradient when running, grey text otherwise
                      color: !isRunning ? "inherit" : "#B7B7B7",
                    }}
                  >
                    Resend OTP
                  </Typography>
                </div>
              </Box>
              <MuiOtpInput
                sx={{
                  marginBottom: "20px",
                  fontWeight: "600",
                  "& .MuiOutlinedInput-root fieldset": {
                    height: "50px",
                    borderColor: otperror ? "red !important" : "#F3F3F3",
                  },
                  "& .MuiInputBase-input": {
                    padding: "12px",
                    fontWeight: "600",
                  },
                  "& .MuiOtpInput-TextField": {},
                }}
                value={otp}
                onChange={(value) => {
                  setOtp(value);
                }}
                length={6}
                autoFocus
                error={otperror}
              />{" "}
              {otperror && (
                <Typography color="error" variant="body2">
                  Incorrect OTP. Please try again.
                </Typography>
              )}
              {/* <button
                onClick={handleOTP}
                disabled={otp.length !== 6}
                className="signup_verify_btn"
              >
                Verify OTP
              </button> */}
            </>
          )}
        </Box>
        <AccountVerifiedModal open={open} email={email} />
      </Grid>
    </Grid>
  );
};

export default SignUp;
