import React from "react";
import { BarStack } from "@visx/shape";
import { Group } from "@visx/group";
import { Grid, GridRows } from "@visx/grid";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { localPoint } from "@visx/event";

// Tooltip styles
const purple1 = "#6c5efb";
const purple2 = "#c998ff";
export const purple3 = "#a44afe";
export const background = "#fff";
const defaultMargin = { top: 40, right: 0, bottom: 0, left: 40 }; // Increased left margin for label space
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 100,
  backgroundColor: "white",
  color: "black",
};

// accessors
const getQuarter = (d) => d.quarter;

let tooltipTimeout;

export default function StackedBarChart({
  width,
  height,
  data = [], // Data passed as a prop
  events = false,
  margin = defaultMargin,
  colors,
  leftLabel = "Score",
}) {
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  if (width < 10 || data.length === 0) return null;

  // Scales
  const dateScale = scaleBand({
    domain: data.map(getQuarter),
    padding: 0.2,
  });

  const yMax = height - margin.top - 30;
  const temperatureScale = scaleLinear({
    domain: [0, 100], // Adjust this based on the maximum possible value in the data
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: ["sox", "nox"], // Stack both "sox" and "nox"
    range: colors,
  });

  // bounds
  const xMax = width - margin.left;
  dateScale.rangeRound([0, xMax]);
  temperatureScale.range([yMax, 0]);

  return (
    <div style={{ position: "relative" }}>
      <svg ref={containerRef} width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={background}
          rx={14}
        />
        {/* <Grid
          top={margin.top}
          left={margin.left}
          xScale={dateScale}
          yScale={temperatureScale}
          width={xMax}
          height={yMax}
          stroke="#F2F2F2"
          strokeDasharray="4 4"
          xOffset={dateScale.bandwidth() / 2}
        /> */}

        {/* <GridRows
          stroke="#F2F2F2"
          strokeDasharray="4 4"
          scale={temperatureScale}
          width={xMax}
          height={yMax}
        /> */}

        <Group left={margin.left} top={margin.top}>
          <BarStack
            data={data}
            keys={["sox", "nox"]} // Stack both "sox" and "nox"
            x={getQuarter}
            xScale={dateScale}
            yScale={temperatureScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    onClick={() => {
                      if (events) alert(`clicked: ${JSON.stringify(bar)}`);
                    }}
                    onMouseLeave={() => {
                      tooltipTimeout = window.setTimeout(() => {
                        hideTooltip();
                      }, 300);
                    }}
                    onMouseMove={(event) => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout);
                      const eventSvgCoords = localPoint(event);
                      const left = bar.x + bar.width / 2;
                      showTooltip({
                        tooltipData: bar,
                        tooltipTop: eventSvgCoords?.y + 10,
                        tooltipLeft: left - 30,
                      });
                    }}
                  />
                ))
              )
            }
          </BarStack>
        </Group>

        <AxisBottom
          hideTicks
          hideAxisLine
          top={yMax + margin.top}
          left={margin.left}
          scale={dateScale}
          numTicks={width > 520 ? 10 : 5}
          tickLabelProps={{
            fontSize: 9,
          }}
        />
        <AxisLeft
          hideTicks
          hideAxisLine
          left={margin.left}
          top={margin.top}
          scale={temperatureScale}
          // numTicks={data.length}
          label={leftLabel}
          labelProps={{
            dx: "1.15em",
            fontWeight: 500,
          }}
          tickLabelProps={{
            dx: "0.5em",
            fontSize: 9,
          }}
        />
      </svg>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            width: 100,
            padding: 12,
            backgroundColor: "white",
            color: "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          {/* <div style={{ color: colorScale(tooltipData.key) }}>
            <strong>{tooltipData.key}</strong>
          </div>
          <div>{tooltipData.bar.data[tooltipData.key]}</div>
          <div>
            <small>{tooltipData.bar.data.quarter}</small>
          </div> */}
          <div
            style={{
              backgroundColor: colorScale(tooltipData.key),
              height: 15,
              width: 15,
            }}
          ></div>
          <div>
            <span>{tooltipData.key}</span>
          </div>
          <div>{tooltipData.bar.data[tooltipData.key]}</div>
        </TooltipInPortal>
      )}
    </div>
  );
}
