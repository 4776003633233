import {
  Box,
  Modal,
  TextField,
  MenuItem,
  FormControl,
  Select,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import trash from "../../../assets/trash.svg";

// Styles for modal content
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  width: "100%",
  backgroundColor: "white",
  boxShadow: 24,
  padding: "2rem",
  borderRadius: "8px",
};

const EditModal = ({ openModal, handleModalClose, selectedRow }) => {
  console.log(selectedRow.userType);
  // State for user type (Can View, Can Edit, etc.)
  const [selectedValue, setSelectedValue] = useState(
    selectedRow?.userType || "can-view"
  );

  // Function to handle the change in the select dropdown
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  // Function to render the selected value
  const renderSelectedValue = (value) => {
    switch (value) {
      case "can-view":
        return "Can View";
      case "can-edit":
        return "Can Edit";
      case "remove":
        return "Removed";
      default:
        return "Can View";
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <h2 className="edit-modal_title">Pending Invite</h2>

        <div className="edit-modal_input_container">
          <div style={{ flexGrow: 1 }}>
            <label
              htmlFor={`email-${selectedRow?.id}`}
              style={{
                marginBottom: "6px",
                display: "block",
                fontSize: "12px",
                fontWeight: 400,
                color: "#585555",
              }}
            >
              Email
            </label>
            <TextField
              id={`email-${selectedRow?.id}`}
              value={selectedRow?.email}
              fullWidth
              variant="outlined"
              sx={{
                borderColor: "#C5D2CB",
                width: "100%",
                "& .MuiInputBase-input": {
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "14px",
                },
              }}
              InputProps={{
                sx: { borderRadius: "8px" },
              }}
            />
          </div>

          <div>
            <FormControl fullWidth>
              <Select
                id={`userType-${selectedRow?.id}`}
                value={selectedValue}
                onChange={handleSelectChange}
                renderValue={(value) => renderSelectedValue(value)}
                sx={{
                  width: "100px",
                  borderRadius: "8px",
                  borderColor: "#C5D2CB",
                  height: "40px",
                  fontSize: "14px",
                  "& .MuiSelect-select": {
                    padding: "8px 14px",
                    display: "flex",
                    alignItems: "center",
                  },
                  "& .MuiSvgIcon-root": {
                    right: "8px",
                  },
                }}
              >
                <MenuItem
                  value="can-view"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <ListItemIcon>
                    {selectedRow.userType === "can-view" && (
                      <CheckIcon fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Can View" />
                </MenuItem>
                <MenuItem
                  value="can-edit"
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  <ListItemIcon>
                    {selectedRow.userType === "can-edit" && (
                      <CheckIcon fontSize="small" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Can Edit" />
                </MenuItem>
                <Divider />
                <MenuItem
                  value="remove"
                  sx={{ color: "red", fontSize: "12px" }}
                >
                  <ListItemIcon>
                    <img src={trash} alt="Delete" height={15} width={15} />
                  </ListItemIcon>
                  <ListItemText primary="Remove" />
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ float: "right" }}>
          <button onClick={handleModalClose} className="edit-modal_close-btn">
            Send Invite
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default EditModal;
