// import React, { useEffect, useState } from "react";
// import { Select, MenuItem } from "@mui/material";
// import messages from "../../../assets/messages.svg";
// import dummyUser from "../../../assets/dummyUser.png";
// import { useLocation, useParams } from "react-router-dom";
// import useFetch from "../../../utils/hooks/useFetch";
// import { reportEndpoints } from "../../../api/endpoints/report.endpoints";

// const BaseSector = ({ reportData, loading }) => {
//   console.log(reportData);
//   const params = useParams();
//   // const { data: baseSectorData, loading } = useFetch(
//   //   reportEndpoints.getReport(params.id)
//   // );

//   const headings = [
//     "Symbol",
//     "Data Point",
//     "Data Point Unit",
//     "Value",
//     "Comments",
//   ];

//   const location = useLocation();
//   // const isAiGenerated = location.state?.generationMethod === "ai";
//   const isAiGenerated = false;

//   // Mapping of API key names to human-readable strings
//   const keyMapping = {
//     scope1Emissions: "Scope 1 emissions",
//     scope2Emissions: "Scope 2 emissions",
//     grossRevenue: "Gross Revenue",
//     totalWaterConsumption: "Total Water Consumption",
//     totalHazardousWaste: "Total Hazardous Waste Produced",
//     totalNonHazardousWaste: "Total Non-Hazardous Waste Produced",
//     totalElectricityConsumption: "Total Electricity Consumption (TEC)",
//     electricityFromGrid: "Electricity Consumed from Grid (ECG)",
//     recPurchased: "REC Purchased for Electricity (REC)",
//     electricityFromRenewables: "Electricity from Renewables (ER)",
//     Y1: "2021",
//     Y2: "2022",
//     Y3: "2023",
//   };

//   // Mapping of unit IDs to human-readable units
//   const unitMapping = {
//     "66d7562107b0aeb0cb66609a": "tCO2e",
//     "66d7562207b0aeb0cb66609d": "million USD",
//     "66d7562207b0aeb0cb6660a4": "m3",
//     "66d7562207b0aeb0cb6660a9": "tonnes",
//     "66d7562207b0aeb0cb6660ad": "MWh",
//     "66d7562207b0aeb0cb6660af": "MWh eq",
//     "66d7562207b0aeb0cb6660ab": "%",
//   };

//   // Function to process the baseSectorData and map it to the table format
//   const processData = (baseData) => {
//     const { id, score, ...other } = baseData;
//     console.log(other);
//     const processedData = [];

//     // Loop through each symbol (B1, B2, etc.)
//     Object.keys(other).forEach((symbol, symbolIndex) => {
//       const dataPoints = [];
//       // Loop through each data point inside the symbol (e.g., scope1Emissions, grossRevenue, etc.)
//       Object.keys(other[symbol]).forEach((key, keyIndex) => {
//         if (key === "score") return;
//         const unit = unitMapping[other[symbol][key].unit] || ""; // Get the unit or fallback to empty
//         const point = keyMapping[key] || key; // Map key to human-readable name or fallback to the key itself

//         dataPoints.push({
//           id: `${symbol}-${keyIndex}`,
//           point,
//           ptKey: key,
//           unit,
//           value: other[symbol][key]?.value || "", // Include the value if available from API
//         });
//       });

//       processedData.push({
//         id: symbolIndex,
//         symbol,
//         dataPoints,
//         messages: "read", // Assuming default messages as 'read'
//       });
//     });

//     return processedData;
//   };

//   const [data, setData] = useState([]);
//   const [basicSectorSpecificKPI, setBasicSectorSpecificKPI] = useState(
//     JSON.parse(localStorage.getItem("baseSector")) || {}
//   );

//   // This function will merge the API data with the data from localStorage
//   const updateData = (apiData) => {
//     const savedData = JSON.parse(localStorage.getItem("baseSector")) || {};

//     for (const key in apiData) {
//       if (typeof apiData[key] === "object") {
//         for (const subKey in apiData[key]) {
//           if (apiData[key][subKey]?.hasOwnProperty("unit")) {
//             // Preserve the unit and only update the value
//             apiData[key][subKey] = {
//               ...apiData[key][subKey], // Keep the unit
//               value:
//                 apiData[key]?.[subKey]?.value ||
//                 savedData[key]?.[subKey]?.value ||
//                 "", // Update the value
//             };
//           }
//         }
//       }
//     }

//     return apiData;
//   };

//   // Effect to handle fetching and setting data
//   useEffect(() => {
//     if (!loading && reportData) {
//       const apiData = reportData;
//       const { id, ...otherApiData } = apiData; // Removing ID if necessary

//       // Update the local state and merge with localStorage data
//       const updatedData = updateData(otherApiData);
//       setBasicSectorSpecificKPI(updatedData);

//       // Process the data for display purposes (if necessary)
//       const processedData = processData(updatedData);
//       setData(processedData);
//     }
//   }, [reportData, loading]);

//   // Handle input changes and persist them to state and localStorage
//   const handleInputChange = (symbol, dataPointKey, event) => {
//     const { value, type } = event.target;

//     // Update the local state without causing a full re-render
//     setBasicSectorSpecificKPI((prevState) => {
//       const updatedState = {
//         ...prevState,
//         [symbol]: {
//           ...prevState[symbol],
//           [dataPointKey]: {
//             ...prevState[symbol][dataPointKey],
//             value: (type === "number" ? +value : value) || "", // Ensure the value is updated correctly
//           },
//         },
//       };

//       // Update localStorage with the new state
//       localStorage.setItem("baseSector", JSON.stringify(updatedState));

//       return updatedState;
//     });

//     // Only update the specific part of the table data, not the entire data object
//     setData((prevData) =>
//       prevData.map((item) =>
//         item.symbol === symbol
//           ? {
//               ...item,
//               dataPoints: item.dataPoints.map((pt) =>
//                 pt.ptKey === dataPointKey ? { ...pt, value: value || "" } : pt
//               ),
//             }
//           : item
//       )
//     );
//   };

//   // Ensure localStorage is updated whenever basicSectorSpecificKPI state changes
//   useEffect(() => {
//     localStorage.setItem("baseSector", JSON.stringify(basicSectorSpecificKPI));
//   }, [basicSectorSpecificKPI]);

//   console.log(basicSectorSpecificKPI);

//   return (
//     <div style={{ width: "100%" }}>
//       <div className="new-kpi-table-container">
//         <div className="new-kpi-table">
//           <div className="new-kpi-table-header">
//             <div className="new-kpi-table-row new-kpi-table-head-row">
//               {headings.map((heading, index) => (
//                 <div
//                   key={index}
//                   className={`new-kpi-table-cell header-cell ${
//                     index === 0 || index > 2 ? "centered" : ""
//                   }`}
//                 >
//                   <div className="new-kpi-table-header-content">{heading}</div>
//                 </div>
//               ))}
//             </div>
//           </div>

//           <div className="new-kpi-table-body">
//             {data.map((item) => {
//               const missingValues = item.dataPoints.some(
//                 (pt) => pt.value === "" || !pt.value
//               );

//               return (
//                 <div
//                   key={item.id}
//                   className="new-kpi-table-row new-kpi-table-body-row"
//                 >
//                   <div
//                     style={{
//                       backgroundColor:
//                         isAiGenerated && missingValues ? "#ffeded" : "white",
//                     }}
//                     className="new-kpi-table-cell centered"
//                   >
//                     <div className="new-kpi-table-cell-content">
//                       {item.symbol}
//                     </div>
//                   </div>
//                   <div className="new-kpi-table-cell">
//                     {item.dataPoints.map((pt) => (
//                       <div
//                         key={pt.point}
//                         className="new-kpi-table-cell-content data"
//                         style={{
//                           backgroundColor:
//                             isAiGenerated && pt.value !== ""
//                               ? "#F2F2F2"
//                               : "white",
//                         }}
//                       >
//                         {pt.point}
//                       </div>
//                     ))}
//                   </div>
//                   <div className="new-kpi-table-cell">
//                     {item.dataPoints.map((pt) => (
//                       <div
//                         key={`${pt.point}-${pt.unit}`}
//                         className="new-kpi-table-cell-content data"
//                         style={{
//                           backgroundColor:
//                             isAiGenerated && pt.value !== ""
//                               ? "#F2F2F2"
//                               : "white",
//                         }}
//                       >
//                         {pt.unit}
//                       </div>
//                     ))}
//                   </div>
//                   <div className="new-kpi-table-cell centered">
//                     {item.dataPoints.map((pt) => {
//                       return (
//                         <div
//                           key={`${pt.id}-${pt.point}-${pt.value}`}
//                           className="new-kpi-table-cell-content input-wrapper"
//                           style={{
//                             backgroundColor:
//                               isAiGenerated && !pt.value ? "#FFEDED" : "",
//                           }}
//                         >
//                           <input
//                             value={pt.value}
//                             type="number"
//                             className="input-field"
//                             placeholder="Enter value"
//                             onChange={(e) =>
//                               handleInputChange(item.symbol, pt.ptKey, e)
//                             }
//                           />
//                         </div>
//                       );
//                     })}
//                   </div>
//                   {/* Comments section */}
//                   <div className="new-kpi-table-cell centered">
//                     <div className="new-kpi-table-cell-content">
//                       {item.messages === "unread" ? (
//                         <div className="messages-wrapper">
//                           <div className="profile-images-container">
//                             {[1, 2, 3, 4, 5].slice(0, 3).map((img) => (
//                               <img
//                                 key={img}
//                                 src={dummyUser}
//                                 alt="Messaged"
//                                 height={28}
//                                 width={28}
//                               />
//                             ))}
//                             {[1, 2, 3, 4, 5].length > 4 && (
//                               <div className="extra-images">
//                                 +{[1, 2, 3, 4, 5].length - 3}
//                               </div>
//                             )}
//                           </div>
//                           <div className="notifications-div">
//                             <img
//                               src={messages}
//                               alt="Messaged"
//                               height={24}
//                               width={24}
//                             />
//                             <span className="unread">2 Unread</span>
//                           </div>
//                         </div>
//                       ) : item.messages === "read" ? (
//                         <div className="messages-wrapper">
//                           <div className="profile-images-container">
//                             {[1, 2, 3, 4].map((img) => (
//                               <img
//                                 key={img}
//                                 src={dummyUser}
//                                 alt="Messaged"
//                                 height={28}
//                                 width={28}
//                               />
//                             ))}
//                           </div>
//                           <div className="notifications-div">
//                             <img
//                               src={messages}
//                               alt="Messaged"
//                               height={24}
//                               width={24}
//                             />
//                             <span className="read">0 Unread</span>
//                           </div>
//                         </div>
//                       ) : (
//                         item.messages === "not assigned" && (
//                           <div
//                             style={{
//                               display: "flex",
//                               alignItems: "center",
//                               justifyContent: "center",
//                             }}
//                           >
//                             <button className="assign-btn">Assign</button>
//                           </div>
//                         )
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BaseSector;

import React, { useCallback, useEffect, useState } from "react";
import dummyUser from "../../../assets/dummyUser.png";
import messages from "../../../assets/messages.svg";
import usePutApi from "../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { useParams } from "react-router-dom";
import {
  debounce,
  filterEmptyObjects,
  removeKeys,
} from "../../../utils/data-points/cleanObj";
import useFetch from "../../../utils/hooks/useFetch";

const BaseSector = () => {
  const params = useParams();
  const { put: saveProgress } = usePutApi(
    reportEndpoints.saveProgress(params.id)
  );
  const { data, loading } = useFetch(reportEndpoints.getReport(params.id));
  const reportData = data?.report?.basicSectorSpecificKPI;
  // const {
  //   data: saveResponse,
  //   loading: saving,
  //   error,
  //   put: saveProgress,
  // } = usePutApi(reportEndpoints.saveProgress(params.id));

  const localStorageKey = `baseSectorKpiData`;

  const headings = [
    "Symbol",
    "Data Point",
    "Data Point Unit",
    "Value",
    "Comments",
  ];

  // Initial state from localStorage or empty object
  // const [dataToMap, setDataToMap] = useState(() => {
  //   const savedData = localStorage.getItem(localStorageKey);
  //   return savedData ? JSON.parse(savedData) : {};
  // });
  const [dataToMap, setDataToMap] = useState({});

  // Sync with reportData only if localStorage is empty
  useEffect(() => {
    if (!loading && reportData && Object.keys(dataToMap).length === 0) {
      const { id, ...other } = reportData;
      const mergedData = { ...other }; // Use only reportData initially if localStorage is empty
      // localStorage.setItem(localStorageKey, JSON.stringify(mergedData)); // Save to localStorage
      setDataToMap(mergedData); // Set state with merged data
    }
  }, [reportData, loading]);

  // Update localStorage on any input change
  const handleInputChange = (symbol, dataKey, value) => {
    setDataToMap((prevState) => {
      const updatedData = {
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value: parseFloat(value), // Update the value while keeping the unit intact
          },
        },
      };

      debouncedSaveProgress(updatedData); // Pass the updated data directly
      return updatedData;
    });
  };

  const sendPutRequest = (updatedData) => {
    const keysRemoved = removeKeys(updatedData, ["id", "score", "unit"]);
    const filteredData = filterEmptyObjects(keysRemoved);

    console.log("Sending PUT request with data:", filteredData);
    console.log(filteredData);
    // Call the API with the updated data
    saveProgress({ basicSectorSpecificKPI: filteredData })
      .then((response) => {
        console.log("PUT request successful:", response.data);
      })
      .catch((error) => {
        console.error("PUT request failed:", error);
      });
  };

  const debouncedSaveProgress = useCallback(
    debounce((updatedData) => {
      sendPutRequest(updatedData); // Use updated data here
    }, 500),
    []
  );

  const keyMapping = {
    scope1Emissions: "Scope 1 emissions",
    scope2Emissions: "Scope 2 emissions",
    grossRevenue: "Gross Revenue",
    totalWaterConsumption: "Total Water Consumption",
    totalHazardousWaste: "Total Hazardous Waste Produced",
    totalNonHazardousWaste: "Total Non-Hazardous Waste Produced",
    totalElectricityConsumption: "Total Electricity Consumption (TEC)",
    electricityFromGrid: "Electricity Consumed from Grid (ECG)",
    recPurchased: "REC Purchased for Electricity (REC)",
    electricityFromRenewables: "Electricity from Renewables (ER)",
    Y1: "2021",
    Y2: "2022",
    Y3: "2023",
  };

  const unitMapping = {
    "66d7562107b0aeb0cb66609a": "tCO2e",
    "66d7562207b0aeb0cb66609d": "million USD",
    "66d7562207b0aeb0cb6660a4": "m3",
    "66d7562207b0aeb0cb6660a9": "tonnes",
    "66d7562207b0aeb0cb6660ad": "MWh",
    "66d7562207b0aeb0cb6660af": "MWh eq",
    "66d7562207b0aeb0cb6660ab": "%",
  };

  const finalOutput = Object.keys(dataToMap).reduce((acc, symbol) => {
    acc[symbol] = Object.keys(dataToMap[symbol]).reduce((innerAcc, dataKey) => {
      innerAcc[dataKey] = { value: dataToMap[symbol][dataKey].value };
      return innerAcc;
    }, {});
    return acc;
  }, {});

  console.log(finalOutput);

  return (
    <div style={{ width: "100%" }}>
      <div className="new-kpi-table-container">
        <div className="new-kpi-table">
          {/* Table Header */}
          <div className="new-kpi-table-header">
            <div className="new-kpi-table-row new-kpi-table-head-row">
              {headings.map((heading, index) => (
                <div
                  key={index}
                  className={`new-kpi-table-cell header-cell ${
                    index === 0 || index > 2 ? "centered" : ""
                  }`}
                >
                  <div className="new-kpi-table-header-content">{heading}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Table Body */}
          {!loading ? (
            <div className="new-kpi-table-body">
              {Object.keys(dataToMap).map((symbol) => {
                if (symbol === "score" || symbol === "grade") return null;
                return (
                  <div
                    key={symbol}
                    className="new-kpi-table-row new-kpi-table-body-row"
                  >
                    {/* Symbol */}
                    <div className="new-kpi-table-cell centered">
                      <div className="new-kpi-table-cell-content">{symbol}</div>
                    </div>

                    {/* Data Points */}
                    <div className="new-kpi-table-cell">
                      {Object.keys(dataToMap[symbol]).map((dataKey, idx) => {
                        if (dataKey === "score") return null;
                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                          >
                            {keyMapping[dataKey] || dataKey}
                          </div>
                        );
                      })}
                    </div>

                    {/* Units */}
                    <div className="new-kpi-table-cell">
                      {Object.keys(dataToMap[symbol]).map((dataKey, idx) => {
                        if (dataKey === "score") return null;
                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                          >
                            {unitMapping[dataToMap[symbol][dataKey].unit] ||
                              "N/A"}
                          </div>
                        );
                      })}
                    </div>

                    {/* Values */}
                    <div className="new-kpi-table-cell centered">
                      {Object.keys(dataToMap[symbol]).map((dataKey, idx) => {
                        if (dataKey === "score") return null;
                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content input-wrapper"
                          >
                            <input
                              type="number"
                              value={dataToMap[symbol][dataKey].value}
                              className="input-field"
                              placeholder="Enter value"
                              onChange={(e) =>
                                handleInputChange(
                                  symbol,
                                  dataKey,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        );
                      })}
                    </div>

                    {/* Messages */}
                    <div className="new-kpi-table-cell centered">
                      <div className="new-kpi-table-cell-content">
                        <div className="messages-wrapper">
                          <div className="profile-images-container">
                            {[1, 2, 3].map((img) => (
                              <img
                                key={img}
                                src={dummyUser}
                                alt="Messaged"
                                height={28}
                                width={28}
                              />
                            ))}
                          </div>
                          <div className="notifications-div">
                            <img
                              src={messages}
                              alt="Messaged"
                              height={24}
                              width={24}
                            />
                            <span className="unread">2 Unread</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>LOADING...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseSector;
