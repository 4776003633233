import { useState } from "react";
import api from "../../api";

const usePostApi = (url, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const post = async (body) => {
    setLoading(true);
    setError(null);
    try {
      const res = await api.post(url, body, options);
      setData(res.data);
      setStatus(res.status);
      return res; // Return result for further actions
    } catch (err) {
      setError(err.response.data.message);
      throw new Error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, post, status };
};

export default usePostApi;
