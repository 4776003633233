import React from "react";

const GradientButton = (props) => {
  const {
    onClick,
    children,
    width = "auto",
    height = "auto",
    fontSize = "0.875rem",
    ...other
  } = props;
  return (
    <button
      style={{ width, height, fontSize }}
      onClick={onClick}
      className="gradient-btn"
      {...other}
    >
      {children}
    </button>
  );
};

export default GradientButton;
