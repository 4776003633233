import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";
import LineAreaGraph from "./LineAreaGraph";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import base from "../../assets/sustainabilityReport/baseSector.svg";
import envGrade from "../../assets/sustainabilityReport/envGrade.svg";
import govGrade from "../../assets/sustainabilityReport/governanceGrade.svg";
import socialGrade from "../../assets/sustainabilityReport/socialGrade.svg";
import SelectFilter from "./SelectFilter";
import PieChart from "./PieChart";
import BreakDownPieChart from "./BreakDownPieChart";
import RadialPieChart from "./RadialPieChart";
import { gradeColors } from "./gradeColors";

const trendParentStyle = {
  height: "267px",
  border: "1px solid #EFEFEF",
  borderRadius: "12px",
};

const BaseSectorCard = ({ icon, title, grade }) => {
  const textColor = gradeColors[grade?.[0]];

  return (
    <Box
      sx={{
        height: "8.7rem",
        width: "100%",
        bgcolor: "#fff",
        p: "1.25rem",
        borderRadius: "12px",
      }}
    >
      <img
        src={icon}
        alt={title}
        height={30}
        width={30}
        style={{ marginBottom: "6px" }}
      />
      <h4 className="base-sector-card_title">{title}</h4>
      <Typography variant="h5" sx={{ color: textColor, fontSize: "20px" }}>
        {grade}
      </Typography>
    </Box>
  );
};

const BaseSectorStats = ({ report }) => {
  console.log(report);
  const [intensityFilter, setIntensityFilter] = useState({
    trend: "",
    denominator: "",
  });

  const pieData = [
    { label: "SCOPE 1", value: 50 },
    { label: "SCOPE 2", value: 30 },
    { label: "SCOPE 3", value: 30 },
  ];

  const data = [
    {
      id: 0,
      icon: base,
      title: "Base Sector",

      grade: report?.basicSectorSpecificKPI?.grade,
    },
    {
      id: 1,
      icon: envGrade,
      title: "Environmental Grade",
      grade: report?.environmentKPI?.grade,
    },
    {
      id: 2,
      icon: socialGrade,
      title: "Social Grade",

      grade: report?.socialKPI?.grade,
    },
    {
      id: 3,
      icon: govGrade,
      title: "Governance Grade",

      grade: report?.governanceKPI?.grade,
    },
  ];

  const intensitiesTableHeading = [
    "X",
    "Total waste generated (tonnes)",
    "GHG emissions: Scope 1 and Scope 2 (tCO2e)",
    "Water consumption (m3)",
  ];

  const intensitiesTableData = [
    {
      x: "per employee",
      totalWaste: "0.21",
      ghg: "0.45",
      waterConsumption: "0.45",
    },
    {
      x: "per million USD of revenue generated",
      totalWaste: "3.15",
      ghg: "67.5",
      waterConsumption: "67.5",
    },
    {
      x: "per m2 of GFA",
      totalWaste: "4.2",
      ghg: "9",
      waterConsumption: "90",
    },
    {
      x: "per unit production",
      totalWaste: "0.105",
      ghg: "0.225",
      waterConsumption: "2.25",
    },
  ];

  const actionableHeading = ["KPI", "", "Actionable Insights", ""];
  const colors = ["#02B880", "#E1F4F3", "#B1E9D8"];

  const actionableData = [
    {
      kpi: "GHG Emissions Intensity",
      actionable1:
        "1. Implement high-efficiency motors (IE3 & IE4), pumps, fans and other equipment to reduce energy consumption and emissions.",
      actionable2:
        "2. Optimize boiler operations with smart control systems to minimize unnecessary energy use and associated emissions",
      actionable3: "3. Involve specialists in Equipment-sizing",
    },
    {
      kpi: "Water Intensity",
      actionable1:
        "1. Involve specialist in equipment selection to reduce water consumption intensity",
      actionable2:
        "2. Use smart metering and IoT sensors for real-time water use monitoring and leak detection.",
      actionable3:
        "3. Upgrade to water-efficient appliances and fixtures in all facilities",
    },
  ];

  const gradeData = [
    { grade: "AA", score: "> 75" },
    { grade: "AB", score: "70-75" },
    { grade: "BB", score: "65-70" },
    { grade: "BC", score: "60-65" },
    { grade: "CC", score: "55-60" },
    { grade: "CD", score: "50-55" },
    { grade: "DD", score: "40-50" },
    { grade: "FF", score: "< 40" },
  ];

  const kpiTableHeadings = ["KPI Code", "KPI", "Unit"];

  const kpiTableData = [
    {
      code: "ONG-a-B1",
      kpi: "GHG emissions intensity",
      unit: "(tCO2e / USD Million Revenue)",
    },
    {
      code: "ONG-a-B2",
      kpi: "Total Water Consumption",
      unit: "m3",
    },
  ];

  return (
    <>
      {/* WHITE DIVS */}
      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        {data.map((item) => (
          <Grid key={item.id} item xs={3}>
            <BaseSectorCard {...item} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2.5} sx={{ mb: "20px" }}>
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2.5,
          }}
        >
          <Box
            sx={{
              height: "142px",
              width: "100%",
              p: "20px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fff",
              borderRadius: "12px",
            }}
          >
            <div>
              <h2>Overall Grade</h2>
              <h3
                style={{
                  fontWeight: 500,
                  fontSize: "20px",
                  color: gradeColors[report?.grade[0]],
                  marginTop: "24px",
                }}
              >
                {report?.grade}
              </h3>
            </div>
          </Box>

          <Box
            sx={{
              // height: "142px",
              height: "24rem",
              width: "100%",
              p: 2,
              // display: "flex",
              backgroundColor: "#fff",
              borderRadius: "12px",
              border: "1px solid black",
            }}
          >
            <h2>Score Break down</h2>
            <Typography sx={{ fontSize: "11px", marginBottom: 5 }}>
              *Total number of KPIs reported: 39/45
            </Typography>
            <div style={{ height: "220px" }}>
              <ParentSize>
                {({ width, height }) => (
                  <RadialPieChart height={height} width={width} />
                )}
              </ParentSize>
            </div>
          </Box>

          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "12px",
              p: 1.5,
              // flexGrow: 1,
              display: "flex",
              // gap: "0.5rem",
              maxHeight: "9rem",
              flexDirection: "column",
            }}
          >
            <Grid container>
              {kpiTableHeadings.map((item) => (
                <Grid
                  key={item}
                  item
                  xs={4}
                  sx={{
                    fontSize: "0.75rem",
                    fontWeight: 500,
                    textAlign: "center",
                    backgroundColor: "#fcfcfc",
                    padding: "0 0 0.5rem 0",
                  }}
                >
                  {item}
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                flexGrow: 1,
                overflow: "auto",
                scrollbarWidth: "thin",
                border: "1px solid #f2f2f2",
                borderRadius: "12px",
              }}
            >
              {kpiTableData.map((item) => {
                return (
                  <Grid
                    key={`${item.code} - ${item.kpi}`}
                    sx={{
                      borderBottom: "1px solid #f2f2f2",
                      fontSize: "0.67rem",
                      fontWeight: 500,
                      height: "100%",
                    }}
                    container
                  >
                    <Grid
                      item
                      xs={4}
                      sx={{
                        padding: "1.5rem 0.5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.code}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        borderLeft: "1px solid #f2f2f2",
                        borderRight: "1px solid #f2f2f2",
                        padding: "1.5rem 0.5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.kpi}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        padding: "1.5rem 0.5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {item.unit}
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={2.5}>
            <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  p: 2,
                  height: "21rem",
                  bgcolor: "white",
                  borderRadius: "12px",
                }}
              >
                <h2>Intensities Table</h2>

                {/* INTENSITIES TABLE */}
                <div
                  style={{
                    backgroundColor: "#fcfcfc",
                    padding: "6px",
                    borderRadius: "12px",
                  }}
                >
                  <Grid container>
                    {intensitiesTableHeading.map((item) => (
                      <Grid
                        key={item}
                        item
                        xs={3}
                        sx={{
                          mt: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "0.562rem",
                          textAlign: "center",
                          fontWeight: 500,
                          p: "5px",
                        }}
                      >
                        {item}
                      </Grid>
                    ))}
                  </Grid>

                  <Grid container sx={{ borderCollapse: "collapse" }}>
                    {intensitiesTableData.map((item, itemIndex) =>
                      Object.entries(item).map(
                        ([key, value], entryIndex, entries) => {
                          // Determine if the item is in one of the corners
                          const isTopLeft = itemIndex === 0 && entryIndex === 0;
                          const isTopRight =
                            itemIndex === 0 &&
                            entryIndex === entries.length - 1;
                          const isBottomLeft =
                            itemIndex === intensitiesTableData.length - 1 &&
                            entryIndex === 0;
                          const isBottomRight =
                            itemIndex === intensitiesTableData.length - 1 &&
                            entryIndex === entries.length - 1;

                          return (
                            <Grid
                              item
                              key={key}
                              xs={3}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontSize: "0.562rem",
                                textAlign: "center",
                                fontWeight: 500,
                                p: "20px 8px",
                                border: "1px solid #E8E8E8",
                                borderTopLeftRadius: isTopLeft ? "8px" : 0,
                                borderTopRightRadius: isTopRight ? "8px" : 0,
                                borderBottomLeftRadius: isBottomLeft
                                  ? "8px"
                                  : 0,
                                borderBottomRightRadius: isBottomRight
                                  ? "8px"
                                  : 0,
                                backgroundColor: "#fff",
                              }}
                            >
                              {value}
                            </Grid>
                          );
                        }
                      )
                    )}
                  </Grid>
                </div>
                {/* INTENSITIES TABLE END*/}
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  p: 2,
                  height: "21rem",
                  bgcolor: "white",
                  borderRadius: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <h2>Intensities Trend</h2>

                  <div style={{ display: "flex", gap: "1rem" }}>
                    <SelectFilter
                      onChange={(e) => console.log(e.target.value)}
                      options={[
                        { name: "option1", value: "option1" },
                        { name: "option2", value: "option2" },
                      ]}
                      value={intensityFilter.trend}
                      placeholder={"Trend"}
                    />
                    <SelectFilter
                      onChange={(e) => console.log(e.target.value)}
                      options={[
                        { name: "option1", value: "option1" },
                        { name: "option2", value: "option2" },
                      ]}
                      value={intensityFilter.denominator}
                      placeholder={"Denominator"}
                    />
                  </div>
                </div>
                <div
                  style={{
                    height: "90.5%",
                    borderRadius: "12px",
                    border: "1px solid #EFEFEF",
                  }}
                  // style={trendParentStyle}
                >
                  <ParentSize>
                    {({ width, height }) => (
                      <LineAreaGraph
                        // colors={colors}
                        // data={data}
                        gradientFrom={"#8debff"}
                        gradientTo={"#fff"}
                        lineColor={"#059BFF"}
                        width={width}
                        height={height}
                      />
                    )}
                  </ParentSize>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  p: 2,
                  height: "22rem",
                  bgcolor: "white",
                  borderRadius: "12px",
                }}
              >
                <h2>SI Score Trend</h2>
                <div
                  style={{
                    height: "89.5%",
                    borderRadius: "12px",
                    border: "1px solid #EFEFEF",
                  }}
                >
                  <ParentSize>
                    {({ width, height }) => (
                      <LineAreaGraph
                        // colors={colors}
                        // data={data}
                        gradientFrom={"#8debff"}
                        gradientTo={"#fff"}
                        lineColor={"#059BFF"}
                        width={width}
                        height={height}
                      />
                    )}
                  </ParentSize>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  p: 2,
                  height: "22rem",
                  display: "flex",
                  flexDirection: "column",
                  bgcolor: "white",
                  borderRadius: "12px",
                }}
              >
                <h2>Actionable Insights</h2>

                {/* ACTIONALBE TABLE */}

                <Grid container>
                  <Grid
                    item
                    xs={2.5}
                    sx={{
                      padding: "10px 0px",
                      fontWeight: 500,
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // whiteSpace: "nowrap",
                    }}
                  >
                    KPI
                  </Grid>
                  <Grid
                    item
                    xs={9.5}
                    sx={{
                      padding: "10px 0px",
                      fontWeight: 500,
                      fontSize: "12px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // whiteSpace: "nowrap",
                    }}
                  >
                    Actionable Insights
                  </Grid>
                </Grid>

                <Grid sx={{ flexGrow: 1, overflow: "auto" }} container>
                  {actionableData.map((item, itemIndex) =>
                    Object.entries(item).map(
                      ([key, value], entryIndex, entries) => {
                        // Determine if the item is in one of the corners
                        const isTopLeft = itemIndex === 0 && entryIndex === 0;
                        const isTopRight =
                          itemIndex === 0 && entryIndex === entries.length - 1;
                        const isBottomLeft =
                          itemIndex === actionableData.length - 1 &&
                          entryIndex === 0;
                        const isBottomRight =
                          itemIndex === actionableData.length - 1 &&
                          entryIndex === entries.length - 1;

                        return (
                          <Grid
                            item
                            key={key}
                            xs={entryIndex === 0 ? 2.5 : 3.1666}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "10px",
                              textAlign: key === "kpi" ? "center" : "left",
                              fontWeight: key === "kpi" ? 500 : 400,
                              p: "4px",
                              border: "1px solid #E8E8E8",
                              borderTopLeftRadius: isTopLeft ? "8px" : 0,
                              borderTopRightRadius: isTopRight ? "8px" : 0,
                              borderBottomLeftRadius: isBottomLeft ? "8px" : 0,
                              borderBottomRightRadius: isBottomRight
                                ? "8px"
                                : 0,
                              lineHeight: "14px",
                            }}
                          >
                            {value}
                          </Grid>
                        );
                      }
                    )
                  )}
                </Grid>

                {/* ACTIONALBE TABLE ENDS */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className="grading-section">
        <Grid container spacing={2.5}>
          <Grid item xs={9}>
            <Box sx={{ p: 2, bgcolor: "white", borderRadius: "12px" }}>
              <div style={{ backgroundColor: "#fcfcfc" }}>
                <Grid container>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      fontSize: "11px",
                      textAlign: "center",
                      p: "20px",
                      fontWeight: 500,
                      // backgroundColor: "#fff",
                    }}
                  >
                    Grade
                  </Grid>
                  {gradeData.map((item, index) => {
                    const isFirstItem = index === 0; // Check if the current item is the first
                    const isLastItem = index === gradeData.length - 1;
                    const color =
                      item.grade[0] === "A"
                        ? "#81DE76"
                        : item.grade[0] === "B"
                        ? "#FFA351"
                        : "#ED4A3C";
                    return (
                      <Grid
                        key={item.grade}
                        item
                        xs={1.25}
                        sx={{
                          color: color,
                          fontSize: "12px",
                          textAlign: "center",
                          p: "20px",
                          fontWeight: 700,
                          border: "1px solid #F2F2F2",
                          backgroundColor: "#fff",
                          borderTopLeftRadius: isFirstItem ? "12px" : 0,
                          borderTopRightRadius: isLastItem ? "12px" : 0,
                        }}
                      >
                        {item.grade}
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      fontSize: "11px",
                      textAlign: "center",
                      p: "20px 0",
                      fontWeight: 500,
                      // backgroundColor: "#fff",
                    }}
                  >
                    Score Range (Sectional/Overall)
                  </Grid>
                  {gradeData.map((item, index) => {
                    const isFirstItem = index === 0; // Check if the current item is the first
                    const isLastItem = index === gradeData.length - 1;
                    return (
                      <Grid
                        key={item.grade}
                        item
                        xs={1.25}
                        sx={{
                          fontSize: "11px",
                          textAlign: "center",
                          p: "20px",
                          fontWeight: 400,
                          border: "1px solid #F2F2F2",
                          backgroundColor: "#fff",
                          borderBottomLeftRadius: isFirstItem ? "12px" : 0,
                          borderBottomRightRadius: isLastItem ? "12px" : 0,
                        }}
                      >
                        {item.score}
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ bgcolor: "white", borderRadius: "12px" }}>
              <Grid container sx={{ height: "140px" }}>
                <Grid
                  item
                  xs={4}
                  sx={{
                    bgcolor: "#ED4A3C",
                    heigh: "100%",
                    borderRadius: "12px 0 0 12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "white",
                  }}
                >
                  Laggard
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    bgcolor: "#FFA351",
                    heigh: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "white",
                  }}
                >
                  Average
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    bgcolor: "#81DE76",
                    heigh: "100%",
                    borderRadius: "0 12px 12px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "white",
                  }}
                >
                  Leader
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BaseSectorStats;
