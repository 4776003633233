import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import "./terms.css";

const TermsModal = ({ isTermsOpen, onClose, onCancel, onAgree }) => {
  return (
    <Modal
      open={isTermsOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: "#fff",
          // border: "2px solid #000",
          boxShadow: 24,
          padding: "3rem",
          maxWidth: "70vw",
          width: "100%",
          maxHeight: "85vh",
          height: "100%",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          // alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "1.6rem",
            fontFamily: "Inter",
            color: "#000",
          }}
          id="modal-modal-title"
          variant="h6"
          component="h2"
        >
          Terms and Conditions
        </Typography>

        <Box
          className="terms-color"
          sx={{
            flexGrow: 1,
            backgroundColor: "#f7f7f7",
            p: 1.5,
            borderRadius: "12px",
            overflow: "auto",
          }}
        >
          <p className="terms-heading">1. Introduction</p>
          <p className="terms-para">
            Welcome to [Emission Web App Name], an application designed to
            assist users in calculating and reporting greenhouse gas (GHG)
            emissions. By accessing or using this app, you agree to comply with
            and be bound by the following terms and conditions. If you do not
            agree with these terms, you must not use the app.
          </p>
          <p className="terms-heading">2. Eligibility</p>
          <p className="terms-para">
            The app is intended for use by individuals or organizations involved
            in GHG emissions reporting or management. Users must be at least 18
            years old to create an account and use the app. By agreeing to these
            terms, you confirm that you meet this age requirement
          </p>

          <p className="terms-heading">3. User Responsibilities</p>
          <p className="terms-para">As a user of the app, you agree to:</p>

          <ul className="terms-list">
            <li>
              Provide accurate and up-to-date information when using the app.
            </li>
            <li>
              Use the app for lawful purposes related to emissions calculations
              and reporting.
            </li>
            <li>Keep your login credentials secure and confidential.</li>
            <li>
              Notify us immediately of any unauthorized use of your account.
            </li>
          </ul>
        </Box>

        <div className="terms-btn_div">
          <button
            className="terms-cancel_btn"
            onClick={() => {
              onCancel();
              onClose();
            }}
          >
            Cancel
          </button>
          <button
            className="terms-accept_btn"
            onClick={() => {
              onAgree();
              onClose();
            }}
          >
            I have read and accept the terms of services
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default TermsModal;
