import React, { useEffect, useState } from "react";
import "./manageAcc.css";
import back from "../../../assets/ArrowLeft.svg";
import brand1 from "../../../assets/images/logo.png";
import uploadIcon from "../../../assets/uploadIcon.svg";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { selectStyles, textFieldStyles } from "../../../styles/inputStyles";
import useFetch from "../../../utils/hooks/useFetch";
import { sectorEndpoints } from "../../../api/endpoints/sector.endpoints";
import { industryEndpoints } from "../../../api/endpoints/industry.endpoints";
import { organizationEndpoints } from "../../../api/apiEndpoints";
import { revenueEndpoints } from "../../../api/endpoints/revenue.endpoints";
import { countryEndpoints } from "../../../api/endpoints/country.endpoints";
import { employeeEndpoints } from "../../../api/endpoints/employee.endpoints";
import constant, { REGEX_PATTERNS } from "../../../constant";
import ImageModal from "../../common/ImageModal";
import defaultOrg from "../../../assets/defaultOrg.svg";
import defaultUser from "../../../assets/images/login/defaultUser.png";
import { useNavigate } from "react-router-dom";
import ImageCropper from "../../common/ImageCropper";
import GradientButton from "../../common/GradientButton";
import { industryApis } from "../../../api/industry.apis";

const ManageAccount = () => {
  const { data: sectorList } = useFetch(sectorEndpoints.getAll);
  // const { data: industryList } = useFetch(industryEndpoints.getIndustriesById());
  const { data: orgData } = useFetch(organizationEndpoints.getOrganization);
  const { data: revenueData } = useFetch(revenueEndpoints.getAll);
  const { data: countryData } = useFetch(countryEndpoints.getAll);
  const { data: employeeCount } = useFetch(employeeEndpoints.getCount);
  // const {} = useP
  const { IMG_URL } = constant;

  console.log(orgData);

  const navigate = useNavigate();

  const [companyDetails, setCompanyDetails] = useState({
    companyImage: "", // Can be a URL or a File
    companyName: "",
    address: "",
    country: "",
    registrationNum: "",
    sector: "",
    industry: "",
    employeeCount: "",
    avgRevenue: "",
  });

  const [ownerDetails, setOwnerDetails] = useState({
    ownerImage: "",
    name: "",
    email: "",
    mobile: "",
  });

  const [industryList, setIndustryList] = useState([]);

  const [openCropper, setOpenCropper] = useState(false); // State to control the cropper modal
  const [currentImageType, setCurrentImageType] = useState(null); // Whether cropping company or owner image
  const [imageToCrop, setImageToCrop] = useState(null); // URL of the image being cropped

  const [croppedCompanyImage, setCroppedCompanyImage] = useState(null); // Cropped image file for the company
  const [croppedOwnerImage, setCroppedOwnerImage] = useState(null);

  const [formErrors, setFormErrors] = useState({});

  const fetchIndustries = async (id) => {
    const res = await industryApis.getIndustriesById(id);
    setIndustryList(res);
  };

  useEffect(() => {
    if (orgData) {
      setCompanyDetails({
        companyImage: `${IMG_URL}/${orgData?.logo}`, // Initially set the URL from the backend
        companyName: orgData?.name,
        country: orgData?.country?.id,
        sector: orgData?.sector?.id,
        industry: orgData?.industry?.id,
        employeeCount: orgData?.employeeCount?.id,
        avgRevenue: orgData?.averageRevenue?.id,
      });
      fetchIndustries(orgData?.sector?.id);
    }
  }, [orgData]);

  const handleCompanyInputChange = async (e) => {
    if (e.target.name === "sector") {
      fetchIndustries(e.target.value);
    }
    setCompanyDetails({ ...companyDetails, [e.target.name]: e.target.value });
  };

  const handleOwnerInputChange = (e) => {
    setOwnerDetails({ ...ownerDetails, [e.target.name]: e.target.value });
  };

  const areAllFieldsFilled = () => {
    // Check required fields for company details
    const requiredCompanyFields = [
      "companyName",
      "country",
      "sector",
      "industry",
      "employeeCount",
      "avgRevenue",
    ];
    const allRequiredCompanyFieldsFilled = requiredCompanyFields.every(
      (field) => companyDetails[field] !== ""
    );

    // Check required fields for owner details
    const requiredOwnerFields = ["name", "email"];
    const allRequiredOwnerFieldsFilled = requiredOwnerFields.every(
      (field) => ownerDetails[field] !== ""
    );

    return allRequiredCompanyFieldsFilled && allRequiredOwnerFieldsFilled;
  };

  const handleSaveData = async (e) => {
    const emailRegex = REGEX_PATTERNS.EMAIL;
    e.preventDefault();
    const isValidEmail = emailRegex.test(ownerDetails?.email);
    if (!isValidEmail)
      setFormErrors({ ...formErrors, email: "Email is invalid!" });
    else {
      setFormErrors({});
      // Create final object for API call
      const finalData = {
        company: {
          ...companyDetails,
          companyImage: croppedCompanyImage
            ? croppedCompanyImage // Use cropped image if available
            : companyDetails.companyImage, // Use original image URL otherwise
        },
        owner: {
          ...ownerDetails,
          ownerImage: croppedOwnerImage // You can do the same for owner image if necessary
            ? croppedOwnerImage
            : ownerDetails.ownerImage,
        },
      };

      console.log("Final Data to send to API:", finalData);
    }
  };

  const handleImageClick = (type) => {
    setCurrentImageType(type); // Set which image (company or owner) is being cropped
    setImageToCrop(
      type === "company" ? companyDetails.companyImage : ownerDetails.ownerImage
    ); // Set the image URL to crop
    setOpenCropper(true); // Open the cropper modal
  };

  const handleNewImageUpload = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setCurrentImageType(type);
      setImageToCrop(imageURL);
      setOpenCropper(true); // Open cropper for new image
    }
  };

  return (
    <div className="manage-account_container">
      <div className="manage-account_heading-div">
        <img
          style={{ cursor: "pointer" }}
          src={back}
          alt="Back"
          height={16}
          width={16}
          onClick={() => navigate("/")}
        />
        <h1>Manage Account</h1>
      </div>

      <div className="account-details_container">
        <h2>Company Details</h2>

        <form onSubmit={handleSaveData}>
          <div className="company-details_form">
            <div className="account-details_upload-div">
              <img
                src={companyDetails?.companyImage || defaultOrg}
                height={70}
                width={70}
                alt="Company Logo"
                style={{ cursor: "pointer", borderRadius: "100%" }}
                onClick={() => {
                  if (
                    companyDetails?.companyImage !== null &&
                    companyDetails?.companyImage !== ""
                  ) {
                    handleImageClick("company");
                  }
                }} // Open image cropper for company image
              />

              <label className="account-details_upload-btn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleNewImageUpload(e, "company")} // Handle new image upload for company
                  style={{ display: "none" }}
                />
                <img src={uploadIcon} height={24} width={24} alt="Upload" />
                <span>
                  {companyDetails?.companyImage ? "Re-upload" : "Upload"}
                </span>
              </label>
            </div>
            <Grid container spacing={2.5}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="companyName"
                  label="Company Name"
                  variant="outlined"
                  required
                  value={companyDetails.companyName}
                  onChange={handleCompanyInputChange}
                  sx={textFieldStyles}
                  placeholder="Enter Company Name"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="address"
                  label="Address"
                  variant="outlined"
                  // required
                  value={companyDetails.address}
                  onChange={handleCompanyInputChange}
                  sx={textFieldStyles}
                  placeholder="Enter Address"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="sector-select"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      color: "#787878",
                      "&.Mui-focused": {
                        color: "#787878",
                      },
                    }}
                  >
                    Country
                  </InputLabel>
                  <Select
                    name="country"
                    labelId="country-select"
                    id="demo-country-select"
                    label="Country"
                    value={companyDetails.country}
                    onChange={handleCompanyInputChange}
                    sx={selectStyles}
                  >
                    {countryData?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="registrationNum"
                  label="Registration Number"
                  variant="outlined"
                  // required
                  value={companyDetails.registrationNum}
                  onChange={handleCompanyInputChange}
                  sx={textFieldStyles}
                  placeholder="Registration number"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="sector-select"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      color: "#787878",
                      "&.Mui-focused": {
                        color: "#787878",
                      },
                    }}
                  >
                    Sector
                  </InputLabel>
                  <Select
                    name="sector"
                    labelId="sector-select"
                    id="demo-simple-select"
                    label="Sector"
                    value={companyDetails.sector}
                    onChange={handleCompanyInputChange}
                    sx={selectStyles}
                  >
                    {sectorList?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="industry-select"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      color: "#787878",
                      "&.Mui-focused": {
                        color: "#787878",
                      },
                    }}
                  >
                    Industry
                  </InputLabel>
                  <Select
                    name="industry"
                    labelId="industry-select"
                    id="demo-industry-select"
                    label="Industry"
                    value={companyDetails.industry}
                    onChange={handleCompanyInputChange}
                    sx={selectStyles}
                  >
                    {industryList?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="employee-select"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      color: "#787878",
                      "&.Mui-focused": {
                        color: "#787878",
                      },
                    }}
                  >
                    Employee Count
                  </InputLabel>
                  <Select
                    name="employeeCount"
                    labelId="employee-select"
                    id="employee-select"
                    label="Employee Count"
                    value={companyDetails.employeeCount}
                    onChange={handleCompanyInputChange}
                    sx={selectStyles}
                  >
                    {employeeCount?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth>
                  <InputLabel
                    id="revenue-select"
                    sx={{
                      fontSize: "14px",
                      mt: "3px",
                      color: "#787878",
                      "&.Mui-focused": {
                        color: "#787878",
                      },
                    }}
                  >
                    Annual Revenue
                  </InputLabel>
                  <Select
                    name="avgRevenue"
                    labelId="revenue-select"
                    id="demo-revenue-select"
                    label="Revenue"
                    value={companyDetails.avgRevenue}
                    onChange={handleCompanyInputChange}
                    sx={selectStyles}
                  >
                    {revenueData?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        sx={{ fontFamily: "Inter" }}
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>

          <div className="owner-details_form">
            <h2>Owner Details</h2>
            <div className="account-details_upload-div">
              <img
                src={ownerDetails?.ownerImage || defaultUser}
                height={70}
                width={70}
                alt="Owner Image"
                style={{ cursor: "pointer", borderRadius: "100%" }}
                onClick={() => {
                  if (
                    ownerDetails?.ownerImage !== null &&
                    ownerDetails?.ownerImage !== ""
                  ) {
                    handleImageClick("owner");
                  }
                }} // Open image cropper for owner image
              />

              <label className="account-details_upload-btn">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleNewImageUpload(e, "owner")} // Handle new image upload for owner
                  style={{ display: "none" }}
                />
                <img src={uploadIcon} height={24} width={24} alt="Upload" />
                {ownerDetails?.ownerImage ? "Re-upload" : "Upload"}
              </label>
            </div>
            <Grid container spacing={2.5}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="name"
                  label="Name"
                  variant="outlined"
                  required
                  value={ownerDetails.name}
                  onChange={handleOwnerInputChange}
                  sx={textFieldStyles}
                  placeholder="Name"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="email"
                  label="Email"
                  variant="outlined"
                  required
                  value={ownerDetails.email}
                  onChange={handleOwnerInputChange}
                  sx={textFieldStyles}
                  placeholder="Email"
                  error={!!formErrors?.email}
                  helperText={formErrors?.email}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="mobile"
                  label="Mobile"
                  variant="outlined"
                  // required
                  value={ownerDetails.mobile}
                  onChange={handleOwnerInputChange}
                  sx={textFieldStyles}
                  placeholder="Mobile No."
                />
              </Grid>
            </Grid>
          </div>

          <div style={{ float: "right", marginBottom: "20px" }}>
            {/* <button
              type="submit"
              disabled={!areAllFieldsFilled() && formErrors}
              className="account-details_save-btn"
            >
              Save
            </button> */}
            <GradientButton
              type="submit"
              disabled={!areAllFieldsFilled() && formErrors}
            >
              Save
            </GradientButton>
          </div>
        </form>
      </div>
      <ImageCropper
        open={openCropper}
        setOpen={setOpenCropper}
        imageUrl={imageToCrop}
        setImageApi={(croppedImageUrl) => {
          if (currentImageType === "company") {
            setCompanyDetails((prev) => ({
              ...prev,
              companyImage: croppedImageUrl,
            }));
          } else {
            setOwnerDetails((prev) => ({
              ...prev,
              ownerImage: croppedImageUrl,
            }));
          }
        }}
        setCroppedImage={(croppedFile) => {
          if (currentImageType === "company") {
            setCroppedCompanyImage(croppedFile);
          } else {
            setCroppedOwnerImage(croppedFile);
          }
        }}
      />
    </div>
  );
};

export default ManageAccount;
