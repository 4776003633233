import {
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Menu,
  Divider,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
  Box,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import threeDots from "../../../assets/threeDots.svg";
import edit from "../../../assets/edit.svg";
import trash from "../../../assets/trash.svg";
import CheckIcon from "@mui/icons-material/Check";
import EditModal from "./EditModal";

const PendingMembers = () => {
  const [selectedValues, setSelectedValues] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false); // State for the modal
  const [selectedRow, setSelectedRow] = useState(null); // State to store the selected row

  // const rows = [
  //   {
  //     id: 1,
  //     name: "Item 1",
  //     description: "Pending 1",
  //     price: "$10",
  //     owner: true,
  //   },
  //   {
  //     id: 2,
  //     name: "Item 2",
  //     description: "Pending 2",
  //     price: "$20",
  //   },
  //   {
  //     id: 3,
  //     name: "Item 3",
  //     description: "Pending 3",
  //     price: "$30",
  //   },
  //   {
  //     id: 4,
  //     name: "Item 4",
  //     description: "Pending 4",
  //     price: "$30",
  //   },
  // ];

  const rows = [
    {
      id: 1,
      email: "user1@example.com",
      inviteDate: "2024-09-01",
      userType: "Owner", // Can also be "Can View", "Can Edit", etc.
    },
    {
      id: 2,
      email: "user2@example.com",
      inviteDate: "2024-09-02",
      userType: "can-view",
    },
    {
      id: 3,
      email: "user3@example.com",
      inviteDate: "2024-09-03",
      userType: "can-edit",
    },
    {
      id: 4,
      email: "user4@example.com",
      inviteDate: "2024-09-04",
      userType: "can-view",
    },
  ];

  const heading = ["Email", "Invite Date", "User Type", ""];

  // Open the menu when the three dots button is clicked
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row); // Store the selected row data
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
    setAnchorEl(null); // Close the menu when opening the modal
  };

  const handleModalClose = () => {
    setOpenModal(false); // Close the modal
  };

  const handleChange = (id, event) => {
    setSelectedValues((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const renderSelectedValue = (value) => {
    switch (value) {
      case "can-view":
        return "Can View";
      case "can-edit":
        return "Can Edit";
      case "remove":
        return "Removed";
      default:
        return "Can View";
    }
  };

  return (
    <div className="table-container">
      <div className="table">
        <div className="table-row table-header">
          {heading.map((item) => (
            <div
              key={item}
              className="table-cell"
              style={{ textAlign: item === "User Type" ? "center" : "left" }}
            >
              {item}
            </div>
          ))}
        </div>

        {rows.map((item) => (
          <div key={item.id} className="table-row">
            {/* <div className="table-cell">{item.name}</div> */}
            <div className="table-cell">{item.email}</div>
            <div className="table-cell">{item.inviteDate}</div>
            <div className="table-cell" style={{ textAlign: "center" }}>
              {item.userType === "owner" ? (
                <span>Owner</span>
              ) : (
                <FormControl
                  sx={{
                    maxWidth: "95px",
                    maxHeight: "38px",
                  }}
                  fullWidth
                >
                  <Select
                    value={selectedValues[item.id] || ""}
                    onChange={(event) => handleChange(item.id, event)}
                    renderValue={(value) => renderSelectedValue(value)}
                    displayEmpty
                    sx={{
                      borderRadius: "8px",
                      borderColor: "#D4D4D4",
                      height: "32px",
                      fontSize: {
                        lg: "12px",
                      },
                      "& .MuiSelect-select": {
                        padding: "8px 14px",
                        display: "flex",
                        alignItems: "center",
                      },
                      "& .MuiSvgIcon-root": {
                        right: "8px",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "8px",
                          fontSize: "12px",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value="can-view"
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      <ListItemIcon>
                        {selectedValues[item.id] === "can-view" && (
                          <CheckIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Can View" />
                    </MenuItem>
                    <MenuItem
                      value="can-edit"
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      <ListItemIcon>
                        {selectedValues[item.id] === "can-edit" && (
                          <CheckIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Can Edit" />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      value="remove"
                      sx={{ color: "red", fontSize: "12px" }}
                    >
                      <ListItemIcon>
                        <img src={trash} alt="Delete" height={15} width={15} />
                      </ListItemIcon>
                      <ListItemText primary="Remove" />
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
            <div className="table-cell">
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openMenu ? "long-menu" : undefined}
                  aria-expanded={openMenu ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event, item)} // Pass the row data
                >
                  <img src={threeDots} alt="Options" height={30} width={30} />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={openMenu}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        borderRadius: "12px",
                        border: "1px solid #8A8686CC",
                        boxShadow: "none",
                      },
                    },
                  }}
                >
                  <MenuItem
                    onClick={handleModalOpen} // Open the modal on click
                    sx={{
                      fontSize: {
                        lg: "12px",
                      },
                    }}
                  >
                    <ListItemIcon>
                      <img src={edit} alt="Edit" height={18} width={18} />
                    </ListItemIcon>
                    <ListItemText primary="Edit" />
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={handleClose}
                    sx={{
                      color: "red",
                      fontSize: {
                        lg: "12px",
                      },
                    }}
                  >
                    <ListItemIcon>
                      <img src={trash} alt="Remove" height={18} width={18} />
                    </ListItemIcon>
                    <ListItemText primary="Remove" />
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal to show row details */}
      <EditModal
        handleModalClose={handleModalClose}
        openModal={openModal}
        selectedRow={selectedRow}
      />
    </div>
  );
};

export default PendingMembers;
