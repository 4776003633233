import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p>Terms and conditions content here.</p>
    </div>
  );
};

export default TermsAndConditions;
