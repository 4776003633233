import { Box, Button, Modal } from "@mui/material";
import sent from "../../assets/inviteSent.png";
import React from "react";
import { useNavigate } from "react-router-dom";
import SuccessAnimation from "../common/SuccessAnimation";

const PasswordChangedModal = ({ isOpen, close }) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    close();
    navigate("/login");
    localStorage.removeItem("resetEmail");
  };

  return (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
      open={isOpen}
      // onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 600,
          bgcolor: "white",
          borderRadius: "8px",
          boxShadow: 24,
          p: "0 40px 40px 40px",
          textAlign: "center",
        }}
      >
        {/* <img src={sent} alt="Success" className="success_image" /> */}
        <SuccessAnimation />
        <h2 className="password_changed">Your Password has been changed</h2>

        <Button
          onClick={handleRedirect}
          sx={{
            width: "80%",
            mt: 2,
            background: "linear-gradient(102deg, #369d9c 0%, #28814d 100%);",
            color: "#fff",
            padding: 1.5,
            textTransform: "none",
            fontSize: "1rem",
          }}
        >
          Go to Log In
        </Button>
      </Box>
    </Modal>
  );
};

export default PasswordChangedModal;
