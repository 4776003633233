function removeKeys(obj, keysToRemove) {
  if (obj === null || typeof obj !== "object") {
    return obj; // Return the value if it's not an object
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeKeys(item, keysToRemove));
  }

  const newObj = {};

  Object?.keys(obj)?.forEach((key) => {
    if (!keysToRemove?.includes(key)) {
      newObj[key] = removeKeys(obj[key], keysToRemove);
    }
  });

  return newObj;
}

function filterEmptyObjects(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj; // Return if it's not an object
  }

  if (Array.isArray(obj)) {
    return obj
      .map((item) => filterEmptyObjects(item))
      .filter(
        (item) =>
          item !== undefined && item !== null && item !== "" && !isNaN(item)
      ); // Exclude NaN
  }

  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === "object" && value !== null) {
      const filteredValue = filterEmptyObjects(value);
      // Only include the key if the object or array has some valid values
      if (Object.keys(filteredValue).length > 0) {
        newObj[key] = filteredValue;
      }
    } else if (
      value !== undefined &&
      value !== null &&
      value !== "" &&
      !isNaN(value) // Exclude NaN values
    ) {
      newObj[key] = value;
    }
  });

  return newObj;
}

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export { removeKeys, filterEmptyObjects, debounce };
