import React from "react";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleOrdinal } from "@visx/scale";

const data = [
  { label: "A", value: 30 },
  { label: "B", value: 70 },
  { label: "C", value: 45 },
  { label: "D", value: 85 },
];

const colorScale = scaleOrdinal({
  domain: data.map((d) => d.label),
  range: ["#f00", "#0f0", "#00f", "#ff0"],
});

const RadialPieChart = ({ height, width }) => {
  const radius = Math.min(width, height) / 2;
  return (
    <svg width={width} height={height}>
      <Group top={height / 2} left={width / 2}>
        <Pie
          data={data}
          pieValue={(d) => d.value}
          outerRadius={radius}
          innerRadius={0}
          cornerRadius={3}
          padAngle={0.02}
        >
          {(pie) =>
            pie.arcs.map((arc) => (
              <g key={arc.data.label}>
                <path d={pie.path(arc)} fill={colorScale(arc.data.label)} />
                <text
                  transform={`translate(${pie.path.centroid(arc)})`}
                  dy=".35em"
                  textAnchor="middle"
                  fill="#fff"
                >
                  {arc.data.label}
                </text>
              </g>
            ))
          }
        </Pie>
      </Group>
    </svg>
  );
};

export default RadialPieChart;
