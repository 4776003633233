import React, { createContext, useContext } from "react";
import { organizationEndpoints } from "../api/apiEndpoints";
import useFetch from "../utils/hooks/useFetch";

// Create the context
export const OrgContext = createContext();

export default function useOrganizationContext() {
  return useContext(OrgContext);
}

export const OrgContextProvider = ({ children }) => {
  // Use the useFetch hook to fetch organization data
  const { data, loading, error } = useFetch(
    organizationEndpoints.getOrganization
  );

  // Render the children components and provide the organization data through context
  return (
    <OrgContext.Provider value={{ data, loading, error }}>
      {children}
    </OrgContext.Provider>
  );
};
