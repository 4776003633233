import {
  FormControl,
  MenuItem,
  Select,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import "./teamMembers.css";
import trash from "../../../assets/trash.svg";

const AllMembers = () => {
  const [selectedValues, setSelectedValues] = useState({});

  const rows = [
    {
      id: 1,
      name: "Item 1",
      description: "Description 1",
      price: "$10",
      owner: true,
    },
    {
      id: 2,
      name: "Item 2",
      description: "Description 2",
      price: "$20",
      owner: false,
    },
    {
      id: 3,
      name: "Item 3",
      description: "Description 3",
      price: "$30",
      owner: false,
    },
    {
      id: 4,
      name: "Item 4",
      description: "Description 4",
      price: "$30",
      owner: false,
    },
    {
      id: 5,
      name: "Item 5",
      description: "Description 5",
      price: "$30",
      owner: false,
    },
  ];

  const heading = ["Member Name", "Email", "Last Active On", "User Type"];

  const handleChange = (id, event) => {
    setSelectedValues((prev) => ({
      ...prev,
      [id]: event.target.value,
    }));
  };

  const renderSelectedValue = (value) => {
    switch (value) {
      case "can-view":
        return "Can View";
      case "can-edit":
        return "Can Edit";
      case "remove":
        return "Removed";
      default:
        return "Can View";
    }
  };

  return (
    <div class="table-container">
      <div class="table">
        <div class="table-row table-header">
          {heading.map((item) => (
            <div key={item} class="table-cell">
              {item}
            </div>
          ))}
        </div>

        {rows.map((item) => (
          <div key={item.id} class="table-row">
            <div class="table-cell">{item.name}</div>
            <div class="table-cell">{item.description}</div>
            <div class="table-cell">{item.price}</div>
            <div class="table-cell">
              {item.owner ? (
                <span>Owner</span>
              ) : (
                <FormControl
                  sx={{
                    maxWidth: "95px",
                    maxHeight: "38px",
                  }}
                  fullWidth
                >
                  <Select
                    value={selectedValues[item.id] || ""}
                    onChange={(event) => handleChange(item.id, event)}
                    renderValue={(value) => renderSelectedValue(value)}
                    displayEmpty
                    sx={{
                      // backgroundColor: "#E9FFF2",
                      borderRadius: "8px",
                      // border: "0.5px solid #D4D4D4",
                      borderColor: "#D4D4D4",
                      height: "32px",
                      fontSize: {
                        lg: "12px",
                      },
                      "& .MuiSelect-select": {
                        padding: "8px 14px",
                        display: "flex",
                        alignItems: "center",
                      },
                      "& .MuiSvgIcon-root": {
                        right: "8px",
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          borderRadius: "8px", // Set border radius for the dropdown
                          fontSize: "12px",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      value="can-view"
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      <ListItemIcon>
                        {selectedValues[item.id] === "can-view" && (
                          <CheckIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Can View" />
                    </MenuItem>
                    <MenuItem
                      value="can-edit"
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      <ListItemIcon>
                        {selectedValues[item.id] === "can-edit" && (
                          <CheckIcon fontSize="small" />
                        )}
                      </ListItemIcon>
                      <ListItemText primary="Can Edit" />
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      value="remove"
                      sx={{ color: "red", fontSize: "12px" }}
                    >
                      <ListItemIcon>
                        <img src={trash} alt="Delete" height={15} width={15} />
                      </ListItemIcon>
                      <ListItemText primary="Remove" />
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllMembers;
