import React, { useCallback, useEffect, useState } from "react";
import dummyUser from "../../../assets/dummyUser.png";
import messages from "../../../assets/messages.svg";
import usePutApi from "../../../utils/hooks/usePutApi";
import { reportEndpoints } from "../../../api/endpoints/report.endpoints";
import { useParams } from "react-router-dom";
import useFetch from "../../../utils/hooks/useFetch";
import {
  debounce,
  filterEmptyObjects,
  removeKeys,
} from "../../../utils/data-points/cleanObj";

const GovernanceKpi = () => {
  const params = useParams();
  const { put: saveProgress } = usePutApi(
    reportEndpoints.saveProgress(params.id)
  );
  const { data, loading } = useFetch(reportEndpoints.getReport(params.id));
  const reportData = data?.report?.governanceKPI;

  const localStorageKey = `governanceKpiData`;

  const headings = [
    "Symbol",
    "Data Point",
    "Data Point Unit",
    "Value",
    "Comments",
  ];

  // Initial state from localStorage or empty object
  // const [dataToMap, setDataToMap] = useState(() => {
  //   const savedData = localStorage.getItem(localStorageKey);
  //   return savedData ? JSON.parse(savedData) : {};
  // });

  const [dataToMap, setDataToMap] = useState({});

  // Sync with reportData only if localStorage is empty
  useEffect(() => {
    if (!loading && reportData && Object.keys(dataToMap).length === 0) {
      const { id, ...other } = reportData;
      const mergedData = { ...other }; // Use only reportData initially if localStorage is empty
      // localStorage.setItem(localStorageKey, JSON.stringify(mergedData)); // Save to localStorage
      setDataToMap(mergedData); // Set state with merged data
    }
  }, [reportData, loading]);

  // Update localStorage on any input change
  const handleInputChange = (symbol, dataKey, value) => {
    setDataToMap((prevState) => {
      const updatedData = {
        ...prevState,
        [symbol]: {
          ...prevState[symbol],
          [dataKey]: {
            ...prevState[symbol][dataKey],
            value:
              value === "true"
                ? true
                : value === "false"
                ? false
                : parseFloat(value), // Handle boolean values
          },
        },
      };
      // localStorage.setItem(localStorageKey, JSON.stringify(updatedData)); // Persist changes in localStorage
      // return updatedData;
      debouncedSaveProgress(updatedData); // Pass the updated data directly
      return updatedData;
    });
  };

  const sendPutRequest = (updatedData) => {
    const keysRemoved = removeKeys(updatedData, ["id", "score", "unit"]);
    const filteredData = filterEmptyObjects(keysRemoved);

    console.log("Sending PUT request with data:", filteredData);

    // Call the API with the updated data
    saveProgress({ governanceKPI: filteredData })
      .then((response) => {
        console.log("PUT request successful:", response.data);
      })
      .catch((error) => {
        console.error("PUT request failed:", error);
      });
  };

  const debouncedSaveProgress = useCallback(
    debounce((updatedData) => {
      sendPutRequest(updatedData); // Use updated data here
    }, 500),
    []
  );

  const keyMapping = {
    taxReliefReceived:
      "Does your company/Product receive Tax Relief/Incentives/Subsidies from the Government?",
    pensionContribution: "Percentage of pension salary contributed by employer",
    localSuppliersSpending: "Amount spent on local suppliers",
    newSuppliersSpending:
      "Amount spent on suppliers having registered business less than 5 years",
    localCommunitiesSpending: "Amount spent on local communities",
    innovativeTechnologiesSpending: "Amount spent on innovative technologies",
    ethicsPolicyInPlace: "Do you have an Ethics policy in place?",
    totalComplaints: "Total Number of complains",
    resolvedComplaints: "Total Number of complains resolved",
    boardMembers: "Number of Board members",
    csrSpending: "Amount spent on CSR activities",
  };

  const unitMapping = {
    "66d7562107b0aeb0cb66609a": "tCO2e",
    "66d7562207b0aeb0cb66609d": "million USD",
    "66d7562207b0aeb0cb6660a4": "m3",
    "66d7562207b0aeb0cb6660a9": "tonnes",
    "66d7562207b0aeb0cb6660ad": "MWh",
    "66d7562207b0aeb0cb6660af": "MWh eq",
    "66d7562207b0aeb0cb6660ab": "%",
    "66d7562207b0aeb0cb6660b3": "dB",
    "66d7562207b0aeb0cb6660b1": "ppm",
    "66d7562307b0aeb0cb6660b7": "Number",
    "66d7562307b0aeb0cb6660b9": "hours/year/employee",
    "66d7562307b0aeb0cb6660bd": "Yes/No", // This will trigger Yes/No buttons
    "66d7562307b0aeb0cb6660bf": "Incidents per 20000 hours worked",
  };

  const finalOutput = Object.keys(dataToMap).reduce((acc, symbol) => {
    acc[symbol] = Object.keys(dataToMap[symbol]).reduce((innerAcc, dataKey) => {
      innerAcc[dataKey] = { value: dataToMap[symbol][dataKey].value };
      return innerAcc;
    }, {});
    return acc;
  }, {});

  console.log("Final Output:", finalOutput);

  return (
    <div style={{ width: "100%" }}>
      <div className="new-kpi-table-container">
        <div className="new-kpi-table">
          {/* Table Header */}
          <div className="new-kpi-table-header">
            <div className="new-kpi-table-row new-kpi-table-head-row">
              {headings.map((heading, index) => (
                <div
                  key={index}
                  className={`new-kpi-table-cell header-cell ${
                    index === 0 || index > 2 ? "centered" : ""
                  }`}
                >
                  <div className="new-kpi-table-header-content">{heading}</div>
                </div>
              ))}
            </div>
          </div>

          {/* Table Body */}
          {!loading ? (
            <div className="new-kpi-table-body">
              {Object.keys(dataToMap).map((symbol) => {
                if (symbol === "score" || symbol === "grade") return null;
                return (
                  <div
                    key={symbol}
                    className="new-kpi-table-row new-kpi-table-body-row"
                  >
                    {/* Symbol */}
                    <div className="new-kpi-table-cell centered">
                      <div className="new-kpi-table-cell-content">{symbol}</div>
                    </div>

                    {/* Data Points */}
                    <div className="new-kpi-table-cell">
                      {Object.keys(dataToMap[symbol]).map((dataKey, idx) => {
                        if (dataKey === "score") return null;
                        return (
                          <div
                            key={idx}
                            className="new-kpi-table-cell-content data"
                          >
                            {keyMapping[dataKey] || dataKey}
                          </div>
                        );
                      })}
                    </div>

                    {/* Units */}
                    <div className="new-kpi-table-cell">
                      {Object.keys(dataToMap[symbol]).map((dataKey, idx) => {
                        if (dataKey === "score") return null;
                        return (
                          <div
                            key={`${dataKey}-${idx}`}
                            className="new-kpi-table-cell-content data"
                          >
                            {unitMapping[dataToMap[symbol][dataKey].unit] ||
                              "N/A"}
                          </div>
                        );
                      })}
                    </div>

                    {/* Values */}
                    <div className="new-kpi-table-cell centered">
                      {Object.keys(dataToMap[symbol]).map((dataKey, idx) => {
                        if (dataKey === "score") return null;
                        const unit =
                          unitMapping[dataToMap[symbol][dataKey].unit];
                        const value = dataToMap[symbol][dataKey].value;
                        if (unit === "Yes/No") {
                          return (
                            <div
                              className="new-kpi-table-cell-content input-wrapper"
                              key={idx}
                            >
                              <button
                                className={`yes-no-btn yes-btn ${
                                  value === true ? "yes-btn-selected" : ""
                                }`}
                                onClick={() =>
                                  handleInputChange(symbol, dataKey, "true")
                                }
                              >
                                Yes
                              </button>
                              <button
                                className={`yes-no-btn no-btn ${
                                  value === false ? "no-btn-selected" : ""
                                }`}
                                onClick={() =>
                                  handleInputChange(symbol, dataKey, "false")
                                }
                              >
                                No
                              </button>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              key={idx}
                              className="new-kpi-table-cell-content input-wrapper"
                            >
                              <input
                                type="number"
                                value={dataToMap[symbol][dataKey].value}
                                className="input-field"
                                placeholder="Enter value"
                                onChange={(e) =>
                                  handleInputChange(
                                    symbol,
                                    dataKey,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          );
                        }
                      })}
                    </div>

                    {/* Messages */}
                    <div className="new-kpi-table-cell centered">
                      <div className="new-kpi-table-cell-content">
                        <div className="messages-wrapper">
                          <div className="profile-images-container">
                            {[1, 2, 3].map((img) => (
                              <img
                                key={img}
                                src={dummyUser}
                                alt="Messaged"
                                height={28}
                                width={28}
                              />
                            ))}
                          </div>
                          <div className="notifications-div">
                            <img
                              src={messages}
                              alt="Messaged"
                              height={24}
                              width={24}
                            />
                            <span className="unread">2 Unread</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>LOADING...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default GovernanceKpi;
