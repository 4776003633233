import React from "react";

const OutlinedButton = (props) => {
  const {
    onClick,
    children,
    width = "auto",
    height = "auto",
    fontSize = "1rem",
    fontWeight = "400",
    ...other
  } = props;
  return (
    <button
      style={{ width, height, fontSize, fontWeight }}
      className="outlined-gradient-btn"
      onClick={onClick}
      {...other}
    >
      {children}
    </button>
  );
};

export default OutlinedButton;
