/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import Pie from "@visx/shape/lib/shapes/Pie";
import { Group } from "@visx/group";
import { animated, useTransition, to } from "@react-spring/web";
import { LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";

// Accessor function for the value
const value = (d) => d.value;

const defaultMargin = { top: 30, right: 30, bottom: 15, left: 30 };

export default function LevelDoughnutChart({
  valueProp = 0, // The actual value you want to display
  maxValue = 100, // The maximum value for the doughnut
  colors = ["#00f", "#ff0", "#fff"], // First two colors are dynamic, third is white for remaining part
  width,
  height,
  margin = defaultMargin,
  animate = true,
  centerText = "", // New prop for center text
  subText = "subtext", // Subtext
  subTextMaxLength = 20, // Set the maximum length of subText per line
  thresholds = { low: 30, medium: 50, high: 70 },
  legend = [],
}) {
  const [selectedSlice, setSelectedSlice] = useState(null);

  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const innerRadius = radius * 0.8;

  // Calculate remaining value
  const remainingValue = maxValue - valueProp;

  // Determine color based on the value compared to the threshold
  const getColor = (value) => {
    if (value < thresholds.low) return colors[0]; // Low value color
    if (value < thresholds.high) return colors[1]; // Medium value color
    return colors[2]; // High value color
  };

  // Create the data for the pie chart
  const data = [
    { label: "Value", value: valueProp, color: getColor(valueProp) },
    { label: "Remaining", value: remainingValue, color: "#fff" },
  ];

  // Split the subtext into multiple lines if it's too long
  const subTextLines = subText
    ? subText.match(new RegExp(`.{1,${subTextMaxLength}}`, "g")) || [subText]
    : [];

  const colorScale = scaleOrdinal({
    domain: legend.map((item) => item.label),
    range: legend.map((item) => item.color),
  });

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <svg width={width} height={height}>
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          {/* Text in the middle of the doughnut */}
          <text
            x={0}
            y={-10}
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: "1.5rem", fontWeight: "bold" }}
          >
            {centerText}
          </text>

          {/* Subtext */}
          {subTextLines.map((line, index) => (
            <text
              key={index}
              x={0}
              y={10 + index * 11} // Adjust the Y position for each line of subText
              textAnchor="middle"
              dominantBaseline="middle"
              style={{ fontSize: 10, fontWeight: "500" }}
            >
              {line}
            </text>
          ))}

          <Pie
            data={data}
            pieValue={value}
            outerRadius={radius} // Use the full radius to fill the chart
            innerRadius={innerRadius} // Set inner radius to create a doughnut chart
            pieSort={(a, b) => a.index - b.index}
          >
            {(pie) => (
              <AnimatedPie
                {...pie}
                animate={animate}
                getKey={(arc) => arc.data.label}
                onClickDatum={({ data: { label } }) =>
                  animate &&
                  setSelectedSlice(
                    selectedSlice && selectedSlice === label ? null : label
                  )
                }
                getColor={(arc) => arc.data.color} // Use the custom color defined in data
              />
            )}
          </Pie>
        </Group>
      </svg>

      {legend.length > 0 && (
        <div style={{ marginTop: 5, width: "100%" }}>
          <LegendOrdinal
            scale={colorScale}
            labelFormat={(label) => label}
            direction="row"
            itemMargin="5px"
            shape="circle"
            shapeHeight={10}
            shapeWidth={10}
            style={{
              display: "flex",
              justifyContent: "center",
              // marginTop: 10,
              fontSize: "0.5rem",
              flexWrap: "wrap",
            }}
          />
        </div>
      )}
    </div>
  );
}

// react-spring transition definitions
const fromLeaveTransition = ({ endAngle }) => ({
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});

const enterUpdateTransition = ({ startAngle, endAngle }) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

function AnimatedPie({ animate, arcs, path, getKey, getColor, onClickDatum }) {
  const transitions = useTransition(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });

  return transitions((props, arc, { key }) => {
    const [centroidX, centroidY] = path.centroid(arc);

    return (
      <g key={key}>
        <animated.path
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            })
          )}
          fill={getColor(arc)}
          stroke="#dbdbdb"
          strokeWidth={"1px"}
          onClick={() => onClickDatum(arc)}
          onTouchStart={() => onClickDatum(arc)}
        />
      </g>
    );
  });
}
