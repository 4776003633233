import { useState } from "react";
import api from "../../api";

const usePutApi = (url, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const put = async (body) => {
    setLoading(true);
    setError(null);
    try {
      const res = await api.put(url, body, options);
      setData(res.data);
      setStatus(res.status);
      return res; // Return result for further actions
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
      throw new Error(err.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, put, status };
};

export default usePutApi;
