import api from ".";
import { industryEndpoints } from "./endpoints/industry.endpoints";

export const industryApis = {
  getIndustriesById: async (id) => {
    try {
      const res = await api.get(industryEndpoints.getIndustriesById(id));
      return res.data;
    } catch (error) {
      throw new Error(error.response);
    }
  },
};
